<template>
  <v-row>
    <v-col cols="12">
      <v-form ref="formFirma">
        <base-card>
          <v-card-title>Registrar Firma Electronica</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-file-input
                  ref="Firma"
                  v-model="dataFirma.Firma"
                  clearable
                  label="Archivo de Firma Electronica"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  ref="Clave"
                  v-model="dataFirma.Clave"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :counter="0"
                  :type="show ? 'text' : 'dataFirma.Clave'"
                  clearable
                  label="Clave"
                  name="input-10-2"
                  prepend-inner-icon="mdi-key"
                  @click:append="show = !show"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-menu
                  ref="VigenciaDesde"
                  v-model="VigenciaDesde"
                  :close-on-content-click="false"
                  :return-value.sync="dataFirma.VigenciaDesde"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field
                      ref="VigenciaDesde"
                      v-model="dataFirma.VigenciaDesde"
                      label="Vigencia Desde"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="formRules"
                      :error-messages="errorMessages"
                      required
                    />
                  </template>
                  <v-date-picker
                    v-model="dataFirma.VigenciaDesde"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="VigenciaDesde = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="$refs.VigenciaDesde.save(dataFirma.VigenciaDesde)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-menu
                  ref="VigenciaHasta"
                  v-model="VigenciaHasta"
                  :close-on-content-click="false"
                  :return-value.sync="dataFirma.VigenciaHasta"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field
                      ref="VigenciaHasta"
                      v-model="dataFirma.VigenciaHasta"
                      label="Vigencia Hasta"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="formRules"
                      :error-messages="errorMessages"
                      required
                    />
                  </template>
                  <v-date-picker
                    v-model="dataFirma.VigenciaHasta"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="VigenciaHasta = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="$refs.VigenciaHasta.save(dataFirma.VigenciaHasta)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-btn
                  class="ma-2"
                  style="background-color:rgb(204 33 40);color:white !important"
                  @click="validateFirma"
                >
                  Registrar
                </v-btn>
              </v-col>
              <v-alert
                v-show="successAlert"
                text
                type="success"
              >
                {{ msgSuccessAlert }}
              </v-alert>
            </v-row>
          </v-card-text>
        </base-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>

  export default {
    name: 'RegisterFirma',

    data () {
      return {
        show: false,
        dataFirma: {},
        VigenciaHasta: '',
        Clave: '',
        VigenciaDesde: '',
        firma: '',
        errorMessages: '',
        successAlert: false,
        msgSuccessAlert: 'Firma cargada correctamente',
        detailsFirma: {},
        formRules: [
          (v) => !!v || 'Este campo es requerido',
        ],
      }
    },
    computed: {
      formUser () {
        return {
          Firma: this.dataFirma.Firma,
          VigenciaDesde: this.dataFirma.VigenciaDesde,
          VigenciaHasta: this.dataFirma.VigenciaHasta,
          Clave: this.dataFirma.Clave,
        }
      },
      statusResponseFirma () {
        return this.$store.state.firmas.statusResponseFirma
      },
    },
    watch:
      {
        statusResponseFirma () {
          if (this.statusResponseFirma === true) {
            this.generateAlertSuccess()
            this.$store.dispatch('firmas/setStatusResponse', false)
          }
        },
      },
    methods: {

      generateAlertSuccess () {
        this.successAlert = true
        this.$store.dispatch('firmas/getListFirmas')
        this.clearForm()
        setTimeout(() => {
          this.successAlert = false
        }, 3000)
      },
      clearForm () {
        this.$refs.formFirma.reset()
      },
      validateFirma () {
        if(this.$refs.formFirma.validate()) {
          this.sendFirma()
        }
      },
      sendFirma () {
        this.$store.dispatch('firmas/insertFirma', {
          ...this.dataFirma,
        })
      },
    },
  }
</script>
